<template>
  <div id="period">
    <v-container grid-list-xs>
      <v-card>
        <v-card-title>
          ข้อมูลการกำหนดงวด
          <v-divider class="mx-4" inset vertical></v-divider>
          <!-- <v-btn color="success" @click="frm_dialog = true"
            ><v-icon left>mdi-account-multiple-plus-outline</v-icon> เพิ่มงวดใหม่</v-btn
          >
          <v-btn color="success" @click="testAddData"
            ><v-icon left>mdi-account-multiple-plus-outline</v-icon> TEST Add
            Member</v-btn
          > -->
          <Frm_period :dialog="frm_dialog" @callback="callback_frm_period"></Frm_period>
          <v-spacer></v-spacer>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>

          <!-- <v-progress-linear v-model="loop" color="amber" height="25">
            <template v-slot:default="{ value }">
              <strong>{{ Math.ceil(value) }}%</strong>
            </template>
          </v-progress-linear> -->
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="items"
          class="elevation-1"
          pagination.sync="pagination"
          item-key="id"
          :search="search"
        >
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import Frm_period from "../components/frm_period";
export default {
  components: { Frm_period },
  name: "period",
  data() {
    return {
      loop: 0,
      search: "",
      headers: [
        { text: "งวดที่", value: "period_number" },
        { text: "วันที่เริ่ม", value: "period_datestart" },
        { text: "วันที่สิ้นสุด", value: "period_dateend" },
        { text: "ยอดจ่ายรายศพ", value: "money_for_pay_member_lose" },
        { text: "ผู้สร้าง", value: "user_modify" },
      ],
      frm_dialog: false,
      items: [],
    };
  },
  methods: {
    callback_frm_period() {
      this.frm_dialog = false;
    },
    async testAddData() {
      this.loop = 0.0;
      // let maxData = 50000;

      // for (let i = 0; i < maxData; i++) {
      await window.$.post(
        this.$store.state.pathAPI + "DB/testDATA",
        // { i: i },
        async (param) => {
          // this.loop = (i * 100) / maxData;
          await window.Swal.fire(param);
          await console.log(param);
        },
        "json"
      );
      // }
    },
  },
};
</script>

<style></style>
